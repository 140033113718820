var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        size: "md",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      on: { ok: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", {
                    attrs: { icon: "inbox-fill", "aria-hidden": "true" },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.header))]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      cancel(), _vm.close()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("validation-observer", {
        ref: "observer2",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ handleSubmit, invalid }) {
              return [
                _c(
                  "div",
                  { staticClass: "mt-2" },
                  [
                    _c("base-datepicker", {
                      attrs: {
                        vid: "from",
                        name: "da",
                        label: "Da",
                        rules: { required: true },
                      },
                      model: {
                        value: _vm.form.from,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "from", $$v)
                        },
                        expression: "form.from",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-2" },
                  [
                    _c("base-datepicker", {
                      attrs: {
                        vid: "to",
                        name: "a",
                        label: "A",
                        rules: { required: true },
                      },
                      model: {
                        value: _vm.form.to,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "to", $$v)
                        },
                        expression: "form.to",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-2" },
                  [
                    _c("base-select", {
                      attrs: {
                        vid: "salesmen",
                        label: "Produttori",
                        name: "produttori",
                        multiple: true,
                        options: _vm.salesmen,
                        taggable: true,
                        closeOnSelect: false,
                        rules: { required: true },
                      },
                      model: {
                        value: _vm.form.broker,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "broker", $$v)
                        },
                        expression: "form.broker",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "mb-2",
                        attrs: { pressed: _vm.myToggle, variant: "light" },
                        on: {
                          "update:pressed": function ($event) {
                            _vm.myToggle = $event
                          },
                          click: function ($event) {
                            _vm.myToggle
                              ? _vm.selectAllBrokers()
                              : _vm.deselectAllBrokers()
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.myToggle
                              ? "Deseleziona tutti"
                              : "Seleziona tutti"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    staticClass: "mt-2 ml-4 float-right",
                    attrs: {
                      type: "button",
                      variant: "outline-lisaweb",
                      size: "sm",
                      disabled: invalid,
                    },
                    on: {
                      click: function ($event) {
                        return handleSubmit(_vm.onGenerate)
                      },
                    },
                  },
                  [_vm._v("\n      Avvia\n    ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }